<template>
  <v-card class="elevation-1 pa-3 school_info" outlined>
    <p style="color: #9ba3ad;margin-bottom: 0;">
      {{ $auth.company("slogan") }}..
    </p>
    <h1 style="margin-bottom: 15px;">{{ $auth.company("name") }}</h1>
    <div class="icon-info">
      <a :href="$auth.company('domain')" target="_blank">
        <v-icon>language</v-icon>
        Website
      </a>
      <a
        href=""
        @click.prevent="redirectSocial($auth.company('social').facebook)"
        :class="
          'facebook ' + ($auth.company('social').facebook ? '' : 'disabled')
        "
      >
        <v-icon>label</v-icon>
        Facebook
      </a>
      <a
        href=""
        @click.prevent="redirectSocial($auth.company('social').twitter)"
        :class="
          'twitter ' + ($auth.company('social').twitter ? '' : 'disabled')
        "
      >
        <v-icon>label</v-icon>
        Twitter
      </a>
      <a
        href=""
        @click.prevent="redirectSocial($auth.company('social').instagram)"
        :class="
          'instagram ' + ($auth.company('social').instagram ? '' : 'disabled')
        "
      >
        <v-icon>label</v-icon>
        Instagram
      </a>
    </div>

    <div style="margin-top: 30px;" class="divider">
      <span><v-icon>account_circle</v-icon>Logged in as</span>
    </div>

    <div class="logged-in-info">
      <div class="decorator"></div>
      <div class="profile">
        <img
          :src="
            $auth.user().profile_image ||
              'https://eacademy.sgp1.cdn.digitaloceanspaces.com/eacademy/school/elU1iAip1hgV2mZo6HV1hxvnLeoVmCIt8oQQQwN7.png'
          "
          alt=""
        />
      </div>
      <div class="pro-info">
        <h4>
          {{
            $auth
              .getRole()
              .charAt(0)
              .toUpperCase() + $auth.getRole().slice(1)
          }}
          <v-icon>bookmark_border</v-icon>
          <span class="logged_in"
            >Logged in at
            <strong style="display:block;margin-top:2px;">{{
              $auth.getLoggedIn()
            }}</strong></span
          >
        </h4>
        <h2>{{ $auth.user().full_name }}</h2>
        <p>{{ $auth.user().email }}</p>
      </div>
    </div>
    <div class="board-static-rep">
      <board></board>
    </div>
  </v-card>
</template>

<script>
import board from "./../../../../components/svg/board";

export default {
  props: {},
  components: {
    board,
  },
  methods: {
    redirectSocial(url) {
      if (!url) return false;
      window.open(url, "_blank");
    },
  },
};
</script>
